import { fetch, Method } from 'core/api/fetch';
import { DateTime } from 'luxon';
import { parseBookingToObject } from '../parser/experiencer';
import { parseContractToModel } from 'core/api/parser/contract';
import {
    parseBookingReservationsToObject,
    parseBookingToModelUpdated,
} from '../parser/booking';

const ExperiencerRoutes = {
    become: '/api/v1.0.0/experiencer/activate_experiencer',
    update: '/api/v1.1.0/experiencer',
    updateAvatar: (experiencer) =>
        `/api/v1.1.0/experiencer/${experiencer}/image`,
    // NEW APIS
    getBookings: (page, size) =>
        `/api/v1.1.0/experiencer/booking?page=${page}&size=${size}`,
    getBookingsCalendar: ({ id, range }) =>
        `/api/v1.1.0/experiencer/booking?experiencesId=[${id}]&view=calendar&start=${range?.start?.toISOString()}&end=${range.end?.toISOString()}`,
    getBooking: (id) => `/api/v1.1.0/experiencer/booking/${id}`,
    updateBooking: (id) => `/api/v1.1.0/experiencer/booking/${id}`,
    getContracts: (page, size, exp, expPage, expSize) =>
        `/api/v1.1.0/experiencer/contract`,
};

const buildGetBookingsQueryParams = ({
    experiencesId,
    email,
    bookingCode,
    showPreviousBookings,
    order,
    direction,
    resources,
    dateSearch,
}) => {
    let query = '';
    let addedParam = false;
    if (experiencesId) {
        query += `experiencesId=[${experiencesId}]`;
        addedParam = true;
    }
    if (email) {
        query += addedParam ? `&email=${email}` : `email=${email}`;
        addedParam = true;
    }
    if (bookingCode) {
        query += addedParam
            ? `&bookingCode=${bookingCode}`
            : `bookingCode=${bookingCode}`;
        addedParam = true;
    }
    if (showPreviousBookings) {
        query += addedParam
            ? `&showPreviousBookings=${showPreviousBookings}`
            : `showPreviousBookings=${showPreviousBookings}`;
        addedParam = true;
    }
    if (order) {
        query += addedParam ? `&column=${order}` : `column=${order}`;
        addedParam = true;
    }
    if (direction) {
        query += addedParam
            ? `&direction=${direction}`
            : `direction=${direction}`;
        addedParam = true;
    }
    if (resources && resources?.length) {
        query += addedParam
            ? `&resources=[${resources}]`
            : `resources=[${resources}]`;
        addedParam = true;
    }
    if (dateSearch && dateSearch.startDate) {
        const startDate = DateTime.fromJSDate(dateSearch.startDate);
        query += addedParam
            ? `&dateSearch=${startDate.toISODate()}`
            : `dateSearch=${startDate.toISODate()}`;
        if (dateSearch.endDate) {
            const endDate = DateTime.fromJSDate(dateSearch.endDate);
            query += `,${endDate.toISODate()}`;
        }
        addedParam = true;
    }
    return '&' + query;
};

export const ExperiencerService = {
    become: async (info) => {
        try {
            const { data } = await fetch(ExperiencerRoutes.become, {
                method: Method.POST,
                authenticated: true,
                data: info,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return data?.status;
        } catch (err) {
            throw new Error(err);
        }
    },

    // update: async (id, newData) => {
    //   const resArr = [];
    //   try {
    //     await fetch(`${ExperiencerRoutes.update}`, {
    //       method: Method.PATCH,
    //       authenticated: true,
    //       data: parseExperiencerToModel(newData, id),
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     }).then((res) => {
    //       resArr.push(res);
    //     });
    //     return resArr;
    //   } catch (err) {
    //     resArr.push(err);
    //     return resArr;
    //   }
    // },
    update: async (info) => {
        try {
            const { data } = await fetch(ExperiencerRoutes.update, {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0].status : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateAvatar: async (id, image) => {
        try {
            const formData = new FormData();
            image =
                image.type && image.type !== ''
                    ? image
                    : image.slice(0, image.size, 'image/png');
            formData.append('images', image, image.name ?? 'avatar.png');
            await fetch(ExperiencerRoutes.updateAvatar(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

    // this method should return bookings w/ their originId on the response
    getBookings: async (
        experiencesId,
        email,
        bookingCode,
        showPreviousBookings,
        page,
        size = 20,
        order,
        direction,
        resources,
        dateSearch
    ) => {
        try {
            const { data } = await fetch(
                `${ExperiencerRoutes.getBookings(
                    page,
                    size,
                    order,
                    direction
                )}${buildGetBookingsQueryParams({
                    experiencesId,
                    email,
                    bookingCode,
                    showPreviousBookings,
                    order,
                    direction,
                    resources,
                    dateSearch,
                })}`,
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );

            const result = data[0];
            return {
                hasNext: result.hasNext,
                bookings: result.bookings.map(parseBookingToObject),
                totalBookings: result.totalBookings,
            };
        } catch (err) {
            throw new Error(err);
        }
    },
    getBookingsCalendar: async (options = {}) => {
        try {
            const { data } = await fetch(
                ExperiencerRoutes.getBookingsCalendar(options),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );

            // const result = data[0];
            return !data.length ? [] : data.map(parseBookingToModelUpdated);
        } catch (err) {
            throw new Error(err);
        }
    },
    getBooking: async (id) => {
        try {
            const { data } = await fetch(ExperiencerRoutes.getBooking(id), {
                method: Method.GET,
                authenticated: true,
            });
            return !data || !data.length ? {} : parseBookingToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    updateBooking: async (info) => {
        try {
            const { data } = await fetch(
                ExperiencerRoutes.updateBooking(info.id),
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: info,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },
    getContracts: async (page, size = 20) => {
        try {
            const { data } = await fetch(
                ExperiencerRoutes.getContracts(page, size),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );

            return !data
                ? { contracts: [], total: 0 }
                : {
                      total: data.total,
                      contracts: data.contracts.map(parseContractToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },
};
