import React, { useState, useRef, useEffect } from 'react';
import { render } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import { Grid, Button } from '@material-ui/core';
import CheckoutItemSelect from './components/CheckoutItemSelect';
import DataGrid from '../DataGrid';
import Title from '../Title';
import PhoneNumber from '../Input/PhoneNumber';
import TextInput from '../Input/Text';
import Select from '../Input/Select';
import Dialog from '../Dialog';
import { dateTimeFormatted } from 'core/libs/utils/dateTimeUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import { DateFormattedUtils } from '../../utils/dateTimeFormatted';
import { MoneyFormattedUtils } from '../../utils/moneyFormatted';
import { Rowing } from '@material-ui/icons';
import { useStripe } from 'core/contexts/stripe';
import { useTranslation } from 'react-i18next';
import PaymentPlatform from 'core/libs/types/PaymentPlatform/PaymentPlatform.mjs';
import CheckoutForm from 'modules/Bookings/components/BookingNew/components/StripeForm';
import { ROUTES } from 'core/routing/routes';

const initialCheckoutItem = {
    experience: '',
    experiencer: '',
    quantity: '',
    resource: '',
    date: '',
};

class QuantityButtonRenderer {
    element;

    constructor(props) {
        this.element = document.createElement('div');

        const { handleOpenAvailabilityComponent } =
            props.columnInfo.renderer.options;
        const { rowKey, grid, row } = props;
        const rowData = grid.getRow(rowKey);

        render(
            <Button
                type="button"
                onClick={() => handleOpenAvailabilityComponent(rowData)}>
                {/* Select Quantity */}
                {rowData?.quantity}
            </Button>,
            this.element
        );
    }
    getElement() {
        return this.element;
    }
    beforeDestroy() {
        this.element.removeChild(this.element.children[0]);
    }
}

class DateButtonRenderer {
    element;

    constructor(props) {
        this.element = document.createElement('div');

        const { handleOpenAvailabilityComponent } =
            props.columnInfo.renderer.options;
        const { rowKey, grid, row } = props;
        const rowData = grid.getRow(rowKey);
        let matchingRow;

        // const matchingRow = '';
        // if (rowData.dateFormatted) {
        //     matchingRow = rowData.dateFormatted.find(
        //         (item) => item.rowKey === rowKey
        //     );
        // } else {
        //     matchingRow = null;
        // }

        render(
            <Button
                style={{ fontSize: '0.8rem' }}
                type="button"
                onClick={(event) => handleOpenAvailabilityComponent(rowData)}>
                {dateTimeFormatted(rowData.dateFormatted, null, {...options, timeZone: rowData.timezone})}
            </Button>,
            this.element
        );
    }
    getElement() {
        return this.element;
    }
    beforeDestroy() {
        this.element.removeChild(this.element.children[0]);
    }
}
class ResourceButtonRenderer {
    element;

    constructor(props) {
        this.element = document.createElement('div');

        const { handleOpenAvailabilityComponent } =
            props.columnInfo.renderer.options;
        const { rowKey, grid, row } = props;
        const rowData = grid.getRow(rowKey);

        render(
            <Button
                type="button"
                style={{ fontSize: '0.6rem' }}
                onClick={(event) => handleOpenAvailabilityComponent(rowData)}>
                {rowData.resource
                    ? rowData?.resource?.map((res) => (
                          <React.Fragment>
                              {res.name}
                              <br />
                          </React.Fragment>
                      ))
                    : 'Select'}
            </Button>,
            this.element
        );
    }
    getElement() {
        return this.element;
    }
    beforeDestroy() {
        this.element.removeChild(this.element.children[0]);
    }
}
class RemoveButtonRenderer {
    element;

    constructor(props) {
        this.element = document.createElement('div');

        const { onRemoveButtonClicked } = props.columnInfo.renderer.options;
        const { rowKey, grid, row } = props;
        const rowdata = grid.getRow(rowKey);

        render(
            <Button
                type="button"
                onClick={() => onRemoveButtonClicked(rowdata)}
                color=""
                startIcon={<DeleteIcon />}></Button>,
            this.element
        );
    }
    getElement() {
        return this.element;
    }
    beforeDestroy() {
        this.element.removeChild(this.element.children[0]);
    }
}

// const initialPaymentPlatforms = {
//     payByCash: false,
//     payByTerminal: false,
//     payByLink: false,
// };

const options = {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
};

// -------------------------------------
// CheckoutPageComponent: Function to handle a checkout
// Este componente deberia tener su propio contexto 
const CheckoutPageComponent = ({
    totalContracts =[],
    totalItems = [],
    contract,
    handleSelectContract = () => {},
    addBooking = () => {},
    addContractBookingPayment = () => {},
    deleteContractBookingPayment = () => {},
    //translate = (t, d) => d || t,
    setSelectedExperienceItem,
    handleClickAvailabilityPopper,
    selectedSlot,
    quantity,
    setQuantity,
    onCloseAvailabilityModal,
    selectedExperienceItem,
    datePicker,
    resourceSelected,
    rawDataCategories,
    useAuth,
    setCheckoutItems,
    addCheckoutItem,
    setBookingCode,
    setBookingId,
    bookingId,
    bookingCode,
    handleModalSuccess,
    ...props
}) => {
    const history = useHistory();

    const { stripePromise } = useStripe();

    const { t: translate, i18n } = useTranslation();

    const { getUserInfoSync } = useAuth();
    const [paymentPlatformId, setPaymentPlatformId] = useState(null);
    const [stripeOpen, setStripeOpen] = useState(false);
    const [stripeClientSecret, setStripeClientSecret] = useState(false);
    const [open, setOpen] = useState(false);

    const [itemsGrid, setItemsGrid] = useState([]);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);
    //const [isPaymentMethodEnabled, setIsPaymentMethodEnabled] = useState(true);
    const [paymentId, setPaymentId] = useState(null);
    const [terminalPaymentId, setTerminalPaymentId] = useState(null);
    const [payNowPrice, setPayNowPrice] = useState(null);
    const [payTotalPrice, setPayTotalPrice] = useState(null);
    const [currency, setCurrency] = useState(null);
    // const [paymentPlatforms, setPaymentPlatforms] = useState(
    //     initialPaymentPlatforms
    // );

    const [acceptedPaymentPlatforms, setAcceptedPaymentPlatforms] = useState([]);


    const gridRef = useRef(null);
    const handleCellClick = ({ rowKey, columnName }) => {
        if (columnName === 'quantity') {
            // setOpen(true);
        }
    };
    
    useEffect(() => {
        getUserInfoSync().then(async (usr) => {
            if (usr.experiencer) {
                setAcceptedPaymentPlatforms(usr.experiencer.acceptedPaymentPlatforms);
            }
        });
    }, []);

    useEffect(() => {
        setItemsGrid([]);
    }, [contract]);
    
    const handleOpenAvailabilityComponent = (e) => {
        setSelectedExperienceItem({ ...e });
        handleClickAvailabilityPopper();
    };

    const handleSelectedExperience = async (e) => {
        // setQuantity(1);
        const newItem = {
            ...e,
            experienceId: e?.experienceId,
            experience: e?.name,
            experiencer: e?.experiencer || e?.experiencerId,
            experienceDetailId: e?.experienceDetailId,
            quantity: 1, 
            resource: '',
            expDetailId: e?.expDetailId,
            name: e?.name,
            status: e?.status,
            date: "",
            totalPrice: !isNaN(e.price) ? parseFloat(e.price) : undefined,
        };
            
        if(newItem.bundleId) {
            // make bundle unique code to get them together
            // make one row per experience
            const bundleUID = Date.now();
            const newItems = newItem.experiences?.map( exp => ({
                ...exp,
                experienceId: exp?.experienceId,
                experience: exp?.name,
                experiencer: exp?.experiencerName || exp?.experiencerId,
                experienceDetailId: exp?.experienceId,
                quantity: 1, 
                resource: '',
                expDetailId: exp?.expDetailId,
                name: exp?.name,
                status: exp?.status,
                date: "",
                bundleExpPrice: exp.price,
                price: exp.price !== null ? exp.price : exp.defaultPrice,
                totalPrice: exp.price !== null ? exp.price : exp.defaultPrice,
                bundle: newItem.name,
                bundleId: newItem.bundleId,
                bundleUID,
            }))
            if(newItems.length) {
                if(!newItems[0]._attributes) { newItems[0]._attributes = {}}
                newItems[0]._attributes.rowSpan = {
                    // Merge rows
                    bundle: newItem?.experiences?.length || 1,
                }
            }
            const auxItems = [...itemsGrid];
            newItems.forEach(it=>auxItems.push(it));
            setItemsGrid(auxItems);

        } else if (newItem?.experienceId) {
            const auxItems = [...itemsGrid];
            auxItems.push(newItem);
            setItemsGrid(auxItems);
            // setCheckoutItems(auxItems);
        }
    };

    const onRemoveButtonClicked = (row) => {
        const indexToRemove = itemsGrid.findIndex(
            (item) => item.rowKey === row.rowKey
        );

        if (indexToRemove !== -1) {
            const auxItems = itemsGrid.filter((it, index) => index !== indexToRemove ).map((it) => {
                    // Improve this!
                    delete it.rowKey;
                    delete it.rowSpanMap;
                    delete it.sortKey;
                    delete it.uniqueKey;
                    delete it._attributes;
                    delete it._disabledPriority;
                    delete it._relationListItemMap;
                    return it;
                
            }); // [...itemsGrid];
            // auxItems.splice(indexToRemove, 1);
            setItemsGrid(auxItems);
        }
    };

    const handlePaymentSelection = (value) => {
        setPaymentMethodSelected(value);
    };
    const getNameResource = (resource) => {
        if (!resource.length) return '';

        const nameArray = [];

        const resourceNames = rawDataCategories.flatMap((resource) =>
            resource.resources.map((item) => {
                return { name: item.name, id: item.id };
            })
        );
        resource.map((res) => {
            const valuesToObject = Object.values(res);
            valuesToObject.map((value) => {
                value.map((v) => {
                    const queso = resourceNames.find((r) => r.id === v);
                    nameArray.push(queso);
                });
            });
        });
        return nameArray;
    };
    useEffect(() => {
        if (!selectedExperienceItem) {
            return;
        }


        const namesResources = getNameResource(resourceSelected);
        // TODO: experiencedetailId and expDetailId are the same data
        const newDataFromSelectedSlot = {
            ...selectedExperienceItem,
            experienceId: selectedExperienceItem.experienceId,
            experience: selectedExperienceItem.experience,
            experiencer: selectedExperienceItem.experiencer,
            checkoutId: selectedExperienceItem.id,
            experienceDetailId: selectedExperienceItem?.experienceDetailId,
            quantity: quantity,
            dateFormatted: selectedSlot?.startUTC,
            date: selectedSlot?.startUTC,
            totalPrice: selectedExperienceItem.bundleId && selectedExperienceItem.bundleExpPrice !== null ? parseFloat(selectedExperienceItem.price) * parseInt(quantity || 1) : !isNaN(selectedSlot?.price) ? parseFloat(selectedSlot?.price) * parseInt(quantity||1) : ( !isNaN(selectedExperienceItem?.price) ? parseFloat(selectedExperienceItem?.price) * parseInt(quantity||1) : undefined),
            price: selectedExperienceItem.bundleId && selectedExperienceItem.bundleExpPrice !== null ? selectedExperienceItem.price : !isNaN(selectedSlot?.price) ? selectedSlot?.price : selectedExperienceItem?.price,
            resource: namesResources,
            // items: [],
            expDetailId: selectedExperienceItem?.experienceDetailId,
            experiencerId: selectedExperienceItem.experiencer,
            name: selectedExperienceItem?.name,
            status: selectedExperienceItem?.status,
            resources: resourceSelected,
            // experienceId: selectedExperienceItem.experienceId,
            // experienceDetailId: selectedExperienceItem?.experienceDetailId,
            // date: selectedSlot?.date,
            // quantity: quantity,
        };

        // newDataFromSelectedSlot.items.push({
        // });
        // const index = auxItems.findIndex(
        //     (item) => item.uniqueKey === newDataFromSelectedSlot.uniqueKey
        // );
            
        const auxItems = [...itemsGrid];
        if (newDataFromSelectedSlot.rowKey !== undefined) {
            auxItems[newDataFromSelectedSlot.rowKey] = newDataFromSelectedSlot;
        } else {
            auxItems.push(newDataFromSelectedSlot);
        }

        setItemsGrid(auxItems);
        // setCheckoutItems(auxItems);
    }, [selectedSlot, quantity, resourceSelected]);

    const columns = [
        {
            header: 'Bundle',
            name: 'bundle',
        },
        {
            header: 'Experience',
            name: 'experience',
        },
        {
            header: 'Tour Operator',
            name: 'experiencer',
            align: 'center',
            // editor: 'text',
        },
        {
            header: 'Quantity',
            name: 'quantity',
            align: 'center',
            renderer: {
                type: QuantityButtonRenderer,
                options: {
                    handleOpenAvailabilityComponent,
                },
            },
        },
        {
            header: 'Resource',
            name: 'resource',
            align: 'center',
            renderer: {
                type: ResourceButtonRenderer,
                options: {
                    handleOpenAvailabilityComponent,
                },
            },
        },
        {
            header: 'Date',
            name: 'dateFormatted',
            align: 'center',
            renderer: {
                type: DateButtonRenderer,
                options: {
                    handleOpenAvailabilityComponent,
                },
            },
        },
        {
            header: 'Unit Price',
            name: 'price',
            align: 'center',
            formatter: ({value}) => {
                return MoneyFormattedUtils(value);
            }
         },
         {
            header: 'Total Price',
            name: 'totalPrice',
            align: 'center',
            formatter: ({value}) => {
                return MoneyFormattedUtils(value);
            }
         },
        {
            header: ' ',
            name: 'removeRow',
            align: 'center',
            minWidth: 75,
            renderer: {
                type: RemoveButtonRenderer,
                options: {
                    onRemoveButtonClicked,
                },
            },
        },
    ];


    const isPaymentPlatformAllowed = (id) => {
        if(!acceptedPaymentPlatforms || !acceptedPaymentPlatforms.length) { return false; }
        return acceptedPaymentPlatforms.some(
            (method) => method.id === id
        );
      };

    const handleSubmit = async (values) => {
        const getProcessedDate = (date) => { 
            const processDate = new Date(date);
            processDate.setMilliseconds(0);
            return processDate.toISOString();
        };
        const tableData = itemsGrid;
        if(!tableData.length) { return; }
        const processedData = tableData.map((item) => {
            return {
                ...item,
                dateUTC: item.date && !isNaN(new Date(item.date).getTime()) ? getProcessedDate(item.date) : '',
            };
        });
        // Separate array in two, experiences and bundles, reduce bundles array and then concat again.
        const expArray = [];
        const bundleMap = {};
        processedData.forEach( data => {
            if(data.bundleUID) {
                if(bundleMap[data.bundleUID]) {
                    if(!Array.isArray(bundleMap[data.bundleUID].experiences)) { bundleMap[data.bundleUID].experiences = [bundleMap[data.bundleUID].experiences]; }
                    bundleMap[data.bundleUID].experiences.push({...data});
                } else {
                    bundleMap[data.bundleUID] = {
                        bundleId: data.bundleId,
                        experiences: [{...data}]
                    }
                }
            } else {
                expArray.push({...data});
            }
        });
        Object.keys(bundleMap).forEach(key => {
            expArray.push({...bundleMap[key]});
          });

        const data = {
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            // quantity: quantity,
            status: values.status,
            // expId: props.expId,
            // subExpId: props.subExpId,
            // calendarView: props.calendarView,
            mobilePhone: values.mobilePhone,
            mobilePhoneCountryCode: values.mobilePhoneCountryCode,
            additional: values.additional,
            // price: values.price, // Why this!?
            // paidPrice: values.paidPrice,
            // hostName: values.hostName,
            // date should be --> 2022-08-20T09:30:00
            // shouldnt the 1st part be the selected date @ calendar?
            // not sure how the service call is received
            // 'props.from.slice(0, idx of T)' --> start date of experience -->
            // 'timeOfArrival' --> HH:mm:ss --> '09:30:00'
            // date: `${props.from.slice(0, props.from.indexOf('T'))}T${timeOfArrival}`,
            // code: contract?.id || null,          // N/A
            code: contract?.id || null,
            paymentPlatform: paymentPlatformId,
            items: expArray,
        };

        // TODO: we pass expIds because of horrible parsing - REVIEW Later
        const responseBooking = await addBooking('', '', data);

        if (responseBooking.length) {
            toast.error(`Hubo un error al intentar crear la reserva!`, {
                position: 'top-right',
                theme: 'colored',
            });
            const auxCheckout = [];
            setCheckoutItems(auxCheckout);
            history.push(ROUTES.BOOKINGS.GLOBAL);
        } else {
            setBookingId(responseBooking.bookingId);
            setBookingCode(responseBooking.bookingCode);
            setPaymentId(responseBooking.paymentId);
            setPayNowPrice(responseBooking.payNow);
            setPayTotalPrice(responseBooking.payTotal);
            setCurrency(responseBooking.currency);
            
            if (parseFloat(responseBooking.payNow) > 0) {
                if(paymentPlatformId === PaymentPlatform.IDS.stripe) {
                    setStripeClientSecret(responseBooking.clientSecret)
                    setStripeOpen(true);
                } else {
                    setOpenPaymentDialog(true);
                    setOpen(true)
                }
            } else {
                handleModalSuccess(true);
                
                setItemsGrid([]);
                }
        }
    };
    const onSubmitConfirmPayment = async (e) => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.cash) {
            hideUID = true;
        } // TODO FIXME We need to use status lib
        if (!terminalPaymentId && !hideUID) {
            return;
        }
        await addContractBookingPayment({
            paymentId,
            paymentUID: terminalPaymentId,
            status: 'approved',
            statusId: 3,
        });
                handleModalSuccess();
    };

    const onDismissConfirmPayment = async (e) => {
        // Call api to delete payment initiation
        if (!paymentId) {
            return;
        }
        await deleteContractBookingPayment({ paymentId });
    };
    const required = (value) => {
        return value ? undefined : translate('form.required');
    };
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const validatePhone = (value) =>
        value && /^(?:(?:\+|00)88|01)?\d{11}$/i.test(value)
            ? undefined
            : 'Invalid phone number';

    const renderBookingForm = () => {
        return (
            <>
                <Form
                    onSubmit={handleSubmit}
                    // initialValues={{ ...values }}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {/* FORM ROWS */}
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        variant="outlined"
                                        label={translate(
                                            'bookingForm.firstname'
                                        )}
                                        name="firstname"
                                        value={values.firstName}
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={required}
                                        variant="outlined"
                                        label={translate(
                                            'bookingForm.lastname'
                                        )}
                                        name="lastname"
                                        value={values.lastName}
                                        component={TextInput}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                        required
                                        validate={(email) => {
                                            if (!email) {
                                                return translate(
                                                    'form.required'
                                                );
                                            }
                                            if (!validateEmail(email)) {
                                                return translate(
                                                    'form.invalid.email'
                                                );
                                            }
                                            return undefined;
                                        }}
                                        variant="outlined"
                                        label="Email"
                                        name="email"
                                        value={values.email}
                                        component={TextInput}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        id="phone"
                                        name="mobilePhone"
                                        component={PhoneNumber}
                                        hintText={translate('profile.phone')}
                                        label={translate('profile.phone')}
                                        validateField={validatePhone}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Field
                                        style={{
                                            marginBottom: '2em',
                                            width: '100%',
                                        }}
                                        id="additional"
                                        name="additional"
                                        component={'textarea'}
                                        label={'Additional Comments'}
                                        placeholder={'Extra info'}
                                        multiline
                                        rows={6}
                                    />
                                </Grid>
                            </Grid>
                            {/* BUTTONS */}
                            <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}
                                style={{ paddingTop: '15px' }}>
                                <Grid item hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.cash)}>
                                    <Button
                                        onClick={() =>
                                            setPaymentPlatformId(PaymentPlatform.IDS.cash)
                                        }
                                        variant="contained"
                                        style={{
                                            backgroundColor:
                                                paymentPlatformId === PaymentPlatform.IDS.cash
                                                    ? '#4CAF50'
                                                    : '#277E2B',
                                            color:
                                            paymentPlatformId === PaymentPlatform.IDS.cash
                                                    ? '#fff'
                                                    : '#fff',
                                        }}>
                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.cash, i18n.language)}
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.terminal)}>
                                    <Button
                                        onClick={() =>
                                            setPaymentPlatformId(PaymentPlatform.IDS.terminal)
                                        }
                                        variant="contained"
                                        style={{
                                            backgroundColor:
                                            paymentPlatformId === PaymentPlatform.IDS.terminal
                                                    ? '#4CAF50'
                                                    : '#277E2B',
                                            color:
                                            paymentPlatformId === PaymentPlatform.IDS.terminal
                                                    ? '#fff'
                                                    : '#fff',
                                        }}>
                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.terminal, i18n.language)}
                                    </Button>
                                </Grid>

                                <Grid item hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.link)}>
                                    <Button
                                        onClick={() =>
                                            setPaymentPlatformId(PaymentPlatform.IDS.link)
                                        }
                                        variant="contained"
                                        style={{
                                            backgroundColor:
                                            paymentPlatformId === PaymentPlatform.IDS.link
                                                    ? '#4CAF50'
                                                    : '#277E2B',
                                            color:
                                            paymentPlatformId === PaymentPlatform.IDS.link
                                                    ? '#fff'
                                                    : '#fff',
                                        }}>
                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.link, i18n.language)}
                                    </Button>
                                </Grid>
                                <Grid item hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.transfer)}>
                                    <Button
                                        onClick={() =>
                                            setPaymentPlatformId(PaymentPlatform.IDS.transfer)
                                        }
                                        variant="contained"
                                        style={{
                                            backgroundColor:
                                            paymentPlatformId === PaymentPlatform.IDS.transfer
                                                    ? '#4CAF50'
                                                    : '#277E2B',
                                            color:
                                            paymentPlatformId === PaymentPlatform.IDS.transfer
                                                    ? '#fff'
                                                    : '#fff',
                                        }}>
                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.transfer, i18n.language)}
                                    </Button>
                                </Grid>
                                <Grid item hidden={!isPaymentPlatformAllowed(PaymentPlatform.IDS.stripe)}>
                                    <Button
                                        onClick={() =>
                                            setPaymentPlatformId(PaymentPlatform.IDS.stripe)
                                        }
                                        variant="contained"
                                        style={{
                                            backgroundColor:
                                            paymentPlatformId === PaymentPlatform.IDS.stripe
                                                    ? '#4CAF50'
                                                    : '#277E2B',
                                            color:
                                            paymentPlatformId === PaymentPlatform.IDS.stripe
                                                    ? '#fff'
                                                    : '#fff',
                                        }}>
                                        {PaymentPlatform.getTranslatedName(PaymentPlatform.types, PaymentPlatform.IDS.stripe, i18n.language)}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                justifyContent="flex-end"
                                style={{ marginTop: '25px' }}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        type="button"
                                        onClick={(event) => {
                                            const auxCheckout = [];
                                            setItemsGrid(auxCheckout);
                                            history.goBack();
                                        }}>
                                        {translate(
                                            'trip.create.buttons.cancel'
                                        )}
                                    </Button>
                                </Grid>
                                <Grid item hidden={!isPaymentPlatformAllowed(paymentPlatformId)}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // disabled={showErrorPrice}
                                        type="submit">
                                        {translate(
                                            'trip.create.buttons.reserve'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
                 <Dialog
                    open={openPaymentDialog}
                    dialogTitle={'Booking Payment'}
                    dialogContent={renderPaymentDialog()}
                    handleClose={() => {
                        onDismissConfirmPayment();
                        setOpenPaymentDialog(false);
                    }}
                    handleSubmit={onSubmitConfirmPayment}
                    labelBack="Cancel"
                    labelNext="Confirm"
                />

                <Dialog
                    open={stripeOpen}
                    dialogTitle={'Booking Confirmation'}
                    hideTitle={true}
                    dialogContent={<CheckoutForm open={stripeOpen} stripePromise={stripePromise} clientSecret={stripeClientSecret}/>}
                    handleClose={() => {
                        // DELETE BOOKING!!!
                        // setExpToDelete(null);
                        onDismissConfirmPayment();
                        setStripeOpen(false);
                    }}
                    handleSubmit={() => {
                        // setSuccessOpen(false);
                        // const auxCheckout = [];
                        // setCheckoutItems(auxCheckout);
                        // history.push(`${ROUTES.BOOKINGS}/${bookingId || ''}`);
                    }}
                    labelBack="Cancel"
                    hideBack={false}
                    labelNext="Ok"
                    hideSubmit={true}
                />
            </>
        );
    };

    const renderPaymentDialog = () => {
        let hideUID = false;
        if (parseInt(paymentPlatformId) === PaymentPlatform.IDS.cash) {
            hideUID = true;
        }
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        {' '}
                        Precio Total:{' '}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
                        {' '}
                        {currency} {MoneyFormattedUtils(payTotalPrice)}{' '}
                    </Grid>
                </Grid>
                <Grid
                    container
                    style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                        {' '}
                        Monto a cobrar(reserva de cupo):{' '}
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}>
                        {' '}
                        {currency} {MoneyFormattedUtils(payNowPrice)}{' '}
                    </Grid>
                </Grid>
                <div hidden={hideUID}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form
                                onSubmit={() => {}}
                                // initialValues={{ ...values }}
                                render={({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            required
                                            // validate={required}
                                            className="add-experience__capacity"
                                            variant="outlined"
                                            label={
                                                'Codigo único de pago del datáfono'
                                            }
                                            name="paymentId"
                                            value={''}
                                            onTextFieldChange={(e) => {
                                                const { name, value } =
                                                    e.target;
                                                setTerminalPaymentId(value);
                                            }}
                                            component={TextInput}
                                        />
                                    </form>
                                )}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

    const headersGrid = {
        height: 75,
        complexColumns: [
            {
                header: 'Service',
                name: 'mergeColumn1',
                childNames: ['bundle', 'experience'],
            },
            {
                header: 'Price',
                name: 'mergeColumn1',
                childNames: ['price', 'totalPrice'],
            },
        ],
    };

    return (
        <Grid container translate="no">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Title value={'SUMMARY'} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Select
                        label={"Agregar contrato"}
                        emptyValue
                        emptyLabel='Sin Contrato'
                        values={totalContracts}
                        onChange={(e) => handleSelectContract(e)}
                        onEmpty={() => handleSelectContract()}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CheckoutItemSelect
                        values={totalItems}
                        onChange={(e) => handleSelectedExperience(e)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        ref={gridRef}
                        data={itemsGrid}
                        header={headersGrid}
                        columnOptions={{
                            minWidth: 100,
                            resizable: true,
                        }}
                        columns={columns}
                        heightResizable={true}
                        rowHeaders={['rowNum']}
                        onClick={handleCellClick}
                        summary={{
                            height: 40,
                            position: 'bottom', // or 'top'
                            columnContent: {
                                totalPrice: {
                                    template: function (valueMap) {
                                        return `TOTAL: ${MoneyFormattedUtils(
                                            valueMap.sum
                                        )}`;
                                    },
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {renderBookingForm()}
                </Grid>

                {/* <Popper id={id} open={open} anchorEl={anchorEl}>
                    <ExperienceAvailabilityPageComponent
                        loading={false}
                        showTitle={false}
                        showQuantity={false}
                        onChange={(date) => {}}
                        onMonthChange={(date) => {
                        }}
                        value={0}
                        data={0}
                        showPrice={false}
                        showAvailability={true}
                        experience={0}
                        setTimeOfArrival={0}
                        addBookedExperienceWithQuantityPrice={(
                            experience,
                            price,
                            quanity
                        ) => {
                            // addBookedExperienceWithQuantityPrice(val);
                            // addCheckoutItem(experience, price, quanity)
                        }}
                        onSlotSelect={(slot) => {
                            // setSelectedSlotPrice(parseFloat(slot.price));
                        }}
                    />
                </Popper> */}
                {/* Probando modal abajo del buttun */}
                {/* <Modal open={open} onClose={handleClose}>
                    <div>
                        <Typography variant="h6">test</Typography>
                        <Typography variant="subtitle1">modal</Typography>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </div>
                </Modal> */}
            </Grid>
        </Grid>
    );
};

export default CheckoutPageComponent;
